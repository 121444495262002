"use strict";

export default {
    created() {
        window.addEventListener("beforeunload", this.confirmSave);
    },
    destroyed () {
        window.removeEventListener("beforeunload", this.confirmSave);
    },
    "methods": {
        confirmSave(event) {
            // ページ離脱・リロード時のアラート
            event.returnValue = "ページを更新すると入力内容が全て失われます。よろしいですか？";
        },
    },
};
