<template>
    <div class="contents">
        <h1 class="pagetitle">お客様情報入力</h1>
        <p v-if="!contents.input.client_id" class="l-mb8 note">アカウントをお持ちの方はこちらから<router-link class="button-login" :to="{ name: 'login' }" type="button" tag="button">ログイン</router-link>してください。</p>
        <p v-else class="l-mb8 note">アカウントにログインしました。</p>
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="div">
            <h2>氏名</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="氏名" rules="max:15|required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="contents.input.name" type="text">
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>電話番号</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="電話番号" rules="numeric|max:15|required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="contents.input.tel" type="text">
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>郵便番号</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="郵便番号" rules="numeric|digits:7|required" tag="div" :immediate="$_getValidatedImmediate()">
                <div class="l-2-20-m--all">
                    <div>
                        <input v-model="contents.input.postal_code" type="text">
                    </div>
                    <div class="is-left">
                        <button class="button-address" type="button" @click="setAddress()">住所入力</button>
                    </div>
                </div>
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>住所</h2>
            <p class="l-mt1 note">※建物名・マンション名・お部屋番号などございましたら必ずご入力ください。</p>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="住所" rules="max:50|required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="contents.input.address" type="text">
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>メールアドレス</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="メールアドレス" rules="email|max:50|required" tag="div" :immediate="$_getValidatedImmediate()">
                <input v-model="contents.input.mail_address" type="text">
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>コメント</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="コメント" rules="max:300" tag="div">
                <textarea v-model="contents.input.comment" rows="5" placeholder="のし等のご要望はこちらにご記入ください。"></textarea>
                <p class="note">のしのご依頼の場合こちらの「<router-link class="button-anchor-link" :to="{ name: 'law', hash: '#productconditions' }" target="_blank" >商品条件</router-link>」をご参考ください。</p>
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
            </ValidationProvider>
            <h2>支払方法</h2>
            <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb9" name="支払方法" rules="required" tag="div" :immediate="$_getValidatedImmediate()">
                <select :value="contents.input.payment_method" @change="confirmPaymentMethod">
                    <option value="">選択してください</option>
                    <!-- <option value="クレジットカード">クレジットカード</option> -->
                    <option value="銀行振込（前払い）">銀行振込（前払い）</option>
                    <option value="代金引換">代金引換</option>
                </select>
                <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                <p v-show="contents.input.payment_method === '銀行振込（前払い）'" class="l-mt2 note">
                    代金前払いとなります。ご注文後にお振込ご案内メールをご登録メールアドレスへ送信いたします。<br>メール内容をご確認のうえお振込みください。
                </p>
                <p v-show="contents.input.payment_method === '代金引換'" class="l-mt2 note">
                    運送会社による代金引換にてお届けいたします。商品お届け時に運送会社ドライバーへお支払いください。
                </p>
            </ValidationProvider>
            <h2>領収証</h2>
            <div class="l-mt2 l-mb9">
                <ValidationProvider name="領収証" rules="" vid="receipt" tag="div">
                    <input id="unnecessaryReceipt" key="unnecessaryReceipt" v-model="checkedrReceipt" name="receipt" value="0" type="radio">
                    <label for="unnecessaryReceipt">なし</label>
                    <input id="necessaryReceipt" key="necessaryReceipt" v-model="checkedrReceipt" name="receipt" value="1" type="radio">
                    <label for="necessaryReceipt">あり</label>
                </ValidationProvider>
                <ValidationProvider v-show="contents.input.receipt === '1'" v-slot="{ errors }" class="l-mt2 " name="宛名" rules="max:30|required_if:receipt,1" tag="div" :immediate="$_getValidatedImmediate()">
                    <input v-model="contents.input.receipt_address" type="text" placeholder="宛名を入力してください。">
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <ValidationProvider v-show="contents.input.receipt === '1'" v-slot="{ errors }" class="l-mt3" name="但し書き" rules="max:30|required_if:receipt,1" tag="div" :immediate="$_getValidatedImmediate()">
                    <input v-model="contents.input.receipt_description" type="text" placeholder="但し書きを入力してください。">
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <ValidationProvider v-show="contents.input.receipt === '1'" class="l-mt3" name="納付先" rules="required_if:receipt,1" tag="div">
                    <input id="address" key="address" v-model="contents.input.receipt_include" name="receipt_include" value="0" type="radio">
                    <label for="address">お客様情報入力住所へ郵送</label>
                    <input id="product" key="product" v-model="contents.input.receipt_include" name="receipt_include" value="1" type="radio">
                    <label for="product">商品へ同梱してお届け</label>
                </ValidationProvider>
            </div>
            <div v-if="!contents.input.client_id">
                <h1 class="pagetitle">上記の内容で、お客様情報を登録しますか？</h1>
                <p class="l-mb8 note">パスワードを入力すると、次回より登録したメールアドレスとパスワードで、お客様の情報を自動で入力できます。登録しない場合はパスワードを空欄にしてください。</p>
                <h2>パスワード</h2>
                <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb9" name="パスワード" rules="password" tag="div">
                    <input v-model="contents.user.password" type="text">
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                    <p class="errorMessage">アカウントログイン時に必要となります。登録のパスワードは必ずお控えください。</p>
                </ValidationProvider>
            </div>
            <ValidationProvider v-if="contents.flagSendData" name="送り先" rules="" vid="flagSendData" tag="div">
                <input id="flag_send_data--checked" :value="contents.flagSendData" type="checkbox" @click="confirmSendData" checked>
                <label for="flag_send_data--checked">お客様情報入力の住所と別の場所へ商品お届け希望</label>
            </ValidationProvider>
            <ValidationProvider v-else name="送り先" rules="" vid="flagSendData" tag="div" :immediate="$_getValidatedImmediate()">
                <input id="flag_send_data" :value="contents.flagSendData" type="checkbox" @click="confirmSendData">
                <label for="flag_send_data">お客様情報入力の住所と別の場所へ商品お届け希望</label>
            </ValidationProvider>
            <div v-show="contents.flagSendData" class="l-mt4">
                <h1 class="pagetitle">送り先情報入力</h1>
                <h2>氏名</h2>
                <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="送り先氏名" rules="max:15|required_if:flagSendData" tag="div" :immediate="$_getValidatedImmediate()">
                    <input v-model="contents.input.send_name" type="text">
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <h2>電話番号</h2>
                <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="送り先電話番号" rules="numeric|max:15|required_if:flagSendData" tag="div" :immediate="$_getValidatedImmediate()">
                    <input v-model="contents.input.send_tel" type="text">
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <h2>郵便番号</h2>
                <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="送り先郵便番号" rules="numeric|digits:7|required_if:flagSendData" tag="div" :immediate="$_getValidatedImmediate()">
                    <div class="l-2-20-m--all">
                        <div>
                            <input v-model="contents.input.send_postal_code" type="text">
                        </div>
                        <div class="is-left">
                            <button class="button-address" type="button" @click="setSendAddress()">住所入力</button>
                        </div>
                    </div>
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
                <h2>住所</h2>
                <p class="l-mt1 note">※建物名・マンション名・お部屋番号などございましたら必ずご入力ください。</p>
                <ValidationProvider v-slot="{ errors }" class="l-mt2 l-mb6" name="送り先住所" rules="max:50|required_if:flagSendData" tag="div" :immediate="$_getValidatedImmediate()">
                    <input v-model="contents.input.send_address" type="text">
                    <p v-show="errors[0]" class="errorMessage">{{ errors[0] }}</p>
                </ValidationProvider>
            </div>
            <div class="l-mt10 l-mb8 is-center">
                <button v-if="!loader" :class="X_classValidatedButton" type="button" :disabled="$_setValidatedDisabled(invalid)" @click="setData()">
                    注文内容確認へ進む
                </button>
                <Loader :loader="loader" />
            </div>
            <div class="is-center">
                <router-link class="button--text-link" :to="{ name: 'shop_list' }" type="button" tag="button">
                    商品注文一覧へ戻る
                </router-link>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    "use strict";

    import Loader from "@/components/Loader.vue";
    import common from "@/mixins/common";
    import confirmSave from "@/mixins/confirmSave";

    export default {
        "components": {
            Loader,
        },
        "mixins": [
            common,
            confirmSave,
        ],
        "props": {
            "contents": {
                "required": true,
                "type": Object,
            },
        },
        data() {
            return {
                "flagUserEdit": false,
                "loader": false,
                "sync": {
                    "address": "",
                },
            };
        },
        "computed": {
            "checkedrReceipt": {
                // 領収証なしの場合、宛名と但し書きをリセット
                get() {
                    return this.contents.input.receipt;
                },
                set(val) {
                    if (val === "0") {
                        this.contents.input.receipt_address = "";
                        this.contents.input.receipt_description = "";
                        this.contents.input.receipt_include = "0";
                    }
                    this.contents.input.receipt = val;
                },
            },
        },
        created() {
            if (!this.contents.input.order.length) {
                this.$router.push({ "name": "shop_list" });
            } else {
                this.$_setValidatedImmediate();
            }
            if (this.contents.flagLogin) {
                // ログイン情報をinputに代入
                for (let key in this.contents.loginData) {
                    if (key in this.contents.input) {
                        this.contents.input[key] = this.contents.loginData[key];
                    }
                }
                this.contents.flagLogin = false;
            }
        },
        "methods": {
            confirmPaymentMethod(e) {
                // 代金引換を選択時に、別途送り先指定があるかを判定
                const value = e.target.value;
                if (value === "代金引換" && this.contents.flagSendData) {
                    const flag = confirm("商品お届け先での代金お支払いとなりますがよろしかったでしょうか。");
                    if (flag) {
                        this.contents.input.payment_method = value;
                    } else {
                        e.preventDefault();
                    }
                } else {
                    this.contents.input.payment_method = value;
                }
            },
            confirmSendData(e) {
                if (this.contents.flagSendData) {
                    // 別途送り先希望なしの場合リセット
                    this.contents.input.send_name = "";
                    this.contents.input.send_tel = "";
                    this.contents.input.send_postal_code = "";
                    this.contents.input.send_address = "";
                    this.contents.flagSendData = !this.contents.flagSendData;
                } else if (this.contents.input.payment_method === "代金引換" && !this.contents.flagSendData) {
                    // 別途送り先指定を選択時に、代金引換かを判定
                    const flag = confirm("商品お届け先での代金お支払いとなりますがよろしかったでしょうか。");
                    if (flag) {
                        this.contents.flagSendData = !this.contents.flagSendData;
                    } else {
                        e.preventDefault();
                    }
                } else {
                    this.contents.flagSendData = !this.contents.flagSendData;
                }
            },
            shouldAccountRegistration() {
                // アカウントの新規登録
                if (!this.contents.input.client_id && this.contents.user.password) {
                    this.contents.input.account_registration = "1";
                }
                // アカウント情報の更新
                this.contents.flagUserUpdate = false;
                if (this.contents.input.client_id) {
                    for (let key in this.contents.user) {
                        if (this.contents.input[key] !== this.contents.loginData[key]) {
                            this.flagUserEdit = true;
                            break;
                        }
                    }
                }
            },
            calcSubTotal() {
                // 小計金額を計算
                let price = 0;
                for (let data of this.contents.input.order) {
                    price += Number(data.price * this.contents.orderCount[data.item_id]);
                }
                return price;
            },
            calcShippingFee(address) {
                // 送料を計算
                const subTotal = this.calcSubTotal();
                const comparisonPrice = 10000;
                const area1 = "北海道";
                const area2 = "沖縄";
                let shippingFee = 0;
                if (address === "error") {
                    shippingFee = "";
                    alert("住所の取得に失敗しました。郵便番号をご確認の上、もう一度ご入力ください。");
                } else if (address.indexOf(area1) !== -1 && subTotal < comparisonPrice) {
                    shippingFee = 1925;
                } else if (address.indexOf(area1) !== -1 && subTotal >= comparisonPrice) {
                    shippingFee = 1100;
                } else if (address.indexOf(area2) !== -1 && subTotal < comparisonPrice) {
                    shippingFee = 2475;
                } else if (address.indexOf(area2) !== -1 && subTotal >= comparisonPrice) {
                    shippingFee = 1650;
                } else if (subTotal < comparisonPrice) {
                    shippingFee = 825;
                } else {
                    shippingFee = 0;
                }
                return shippingFee;
            },
            async calcTotal() {
                // 合計金額を計算
                if (this.contents.input.send_postal_code) {
                    this.findAddress(this.contents.input.send_postal_code);
                } else if (this.contents.input.postal_code) {
                    this.findAddress(this.contents.input.postal_code);
                }
                const address = await this.sync.address;
                const shippingFee = this.calcShippingFee(address);
                const subTotal = this.calcSubTotal();
                if (shippingFee || shippingFee === 0) {
                    this.contents.input.shipping_fee = String(shippingFee);
                    this.contents.input.subtotal = String(subTotal);
                    this.contents.input.total = String(subTotal + shippingFee);
                    this.goToNext();
                } else {
                    this.loader = false;
                }
            },
            setData() {
                this.loader = true;
                // 確認画面へ
                this.shouldAccountRegistration();
                this.calcTotal();
            },
            goToNext() {
                if (this.flagUserEdit) {
                    // 登録内容と入力に差分があった場合
                    this.contents.flagUserUpdate = confirm("登録内容を更新しますか？");
                }
                this.$router.push({ "name": "confirm" });
            },
            async setAddress() {
                // 自動住所取得で住所をset
                if (this.contents.input.postal_code) {
                    this.findAddress(this.contents.input.postal_code);
                    const address = await this.sync.address;
                    if (address === "error") {
                        alert("住所の取得に失敗しました。郵便番号をご確認の上、もう一度ご入力ください。");
                        this.contents.input.postal_code = "";
                    } else {
                        this.contents.input.address = address;
                    }
                    this.sync.address = "";
                } else {
                    alert("郵便番号が未入力です。");
                }
            },
            async setSendAddress() {
                // 自動住所取得で送り先住所をset
                if (this.contents.input.send_postal_code) {
                    this.findAddress(this.contents.input.send_postal_code);
                    const address = await this.sync.address;
                    if (address === "error") {
                        alert("住所の取得に失敗しました。郵便番号をご確認の上、もう一度ご入力ください。");
                        this.contents.input.send_postal_code = "";
                    } else {
                        this.contents.input.send_address = address;
                    }
                    this.sync.address = "";
                } else {
                    alert("送り先郵便番号が未入力です。");
                }
            },
            findAddress(postalCode) {
                // 自動住所取得
                this.sync.address = common.$_axios.get(
                    `${common.$_configs.apiUrl}/auto_address/${postalCode}`,
                    this.$_requestConfig()
                ).then(response => {
                    return response.data;
                }).catch(() => {
                    return "error";
                });
            },
        },
    };
</script>
